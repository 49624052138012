<template>
  <div>
    <button-top
      v-if="permissions.u"
      :must-icon="false"
      tooltip="Guardar ajuste"
      name="Guardar"
      @action="adjustment"
    ></button-top>

    <validation-observer ref="form">
      <b-form>
        <b-row v-if="type === 'addition'">
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="planting_date" class="text-success"
                >Fecha de siembra</label
              >
              <validation-provider
                #default="{ errors }"
                name="Fecha de siembra"
                rules="required"
              >
                <b-form-datepicker
                  id="planting_date"
                  class="custom-datepicker"
                  v-model="form.planting_date"
                  :state="errors.length > 0 ? false : null"
                  name="planting_date"
                  hide-header
                  dropright
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }"
                  style="z-index: 100"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="type === 'seedling'" class="mb-1">
          <b-col cols="2" class="font-weight-bold"> Especie </b-col>
          <b-col>
            {{ `${information.specie_name} / ${information.packaging_name}` }}
          </b-col>
        </b-row>

        <b-row v-if="type === 'seedling'">
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="product_id" class="text-success"
                >Nuevo producto</label
              >
              <validation-provider
                #default="{ errors }"
                name="Nuevo producto"
                rules="required"
              >
                <b-select-created
                  name="product_id"
                  id="product_id"
                  v-model="form.new_product_id"
                  :options="list.products"
                  on-value="id"
                  on-text="name"
                  :created="list.species_not_created.length > 0"
                  @create="productPopup"
                  :state="errors.length > 0 ? false : null"
                >
                </b-select-created>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="nursery_id" class="text-success">Vivero</label>
              <b-form-select
                id="nursery_id"
                class="fl"
                v-model="filter.nursery_id"
                value-field="id"
                text-field="name"
                :options="list.nurseries"
                name="nursery_id"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="mt-2">
          <b-table :items="form.inventory" :fields="fields" bordered small>
            <template #thead-top>
              <b-tr>
                <b-th class="bg-dark text-white" :colspan="fields.length"
                  >Inventario en producción
                </b-th>
              </b-tr>
            </template>

            <template #cell(action)="row">
              <b-row align-h="center">
                <feather-icon
                  @click="remove(row.index)"
                  v-b-tooltip.hover.top="'Eliminar'"
                  icon="MinusCircleIcon"
                  size="16"
                  color="red"
                  class="cursor-pointer"
                />
              </b-row>
            </template>
            <template #cell(quantity)="row">
              <validation-provider
                #default="{ errors }"
                name="Cantidad"
                :rules="
                  'required|min_value:1|' +
                  (type === 'addition' ? '' : `max_value:${row.item.available}`)
                "
              >
                <b-form-input
                  id="quantity"
                  class="w-100"
                  v-model="row.item.quantity"
                  :state="errors.length > 0 ? false : null"
                  name="quantity"
                  type="number"
                />
              </validation-provider>
            </template>

            <template #cell(area_destination)="row">
              <validation-provider
                #default="{ errors }"
                name="Era destino"
                rules="required"
              >
                <b-form-select
                  id="area_destination_id"
                  class="fl"
                  v-model="row.item.area_destination_id"
                  value-field="id"
                  text-field="name"
                  :options="row.item.options"
                  :state="errors.length > 0 ? false : null"
                  name="area_destination_id"
                ></b-form-select>
              </validation-provider>
            </template>
          </b-table>

          <b-row align-h="end">
            <b-button
              v-if="permissions.c"
              variant="outline-primary"
              class="mr-2"
              @click="add()"
            >
              <feather-icon icon="PlusIcon" size="16" />
              <span class="mr-25 align-middle">Adicionar registro</span>
            </b-button>
          </b-row>
        </div>
        <b-row>
          <b-col cols="12">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Observacion</label>
              <validation-provider
                #default="{ errors }"
                name="Observacion"
                rules="required"
              >
                <b-form-textarea
                  id="observation"
                  v-model="form.observation"
                  :state="errors.length > 0 ? false : null"
                  name="observation"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-modal
      v-model="create_product"
      :title="`Agregar para la especie: ${information.packaging_name}`"
      size="md"
      hide-footer
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <validation-observer ref="form_product">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Empaque</label>
              <validation-provider
                #default="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-select
                  id="packaging_id"
                  class="fl"
                  v-model="form_product.packaging_id"
                  value-field="id"
                  text-field="packaging_name"
                  :options="list.species_not_created"
                  name="packaging_id"
                  :state="errors.length > 0 ? false : null"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="4">
          <b-button
            variant="outline-secondary"
            block
            @click="create_product = false"
            >Cancelar</b-button
          >
        </b-col>
        <b-col cols="4">
          <b-button variant="primary" block @click="storeProduct()"
            >Crear</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      information: {
        id: "",
        specie_name: "",
        packaging_name: "",
        height: 0,
        production_days: 0,
        description: "",
      },

      form: {
        id: "",
        planting_date: null,
        product_id: null,
        new_product_id: null,
        inventory: [],
        observation: "",
      },

      form_product: {
        specie_id: "",
        packaging_id: "",
      },

      filter: {
        nursery_id: "",
      },
      type: "",
      create_product: false,
      list: {
        nurseries: [],
        products: [],
        species_not_created: [],
      },
      action: {
        key: "action",
        label: "",
        thStyle: { width: "4%" },
      },
      nursery: {
        key: "nursery",
        label: "Vivero",
      },
      area: {
        key: "area",
        label: "Era",
      },

      available: {
        key: "available",
        label: "Disponible",
      },
      quantity: {
        key: "quantity",
        label: "Cantidad",
      },
      area_origin: {
        key: "area_origin",
        label: "Era origen",
      },
      area_destination: {
        key: "area_destination",
        label: "Era destino",
      },
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.type = this.$route.params.type;
      this.form.product_id = this.$route.params.id;
      await this.getPermission();
      if (!this.permissions.u || !this.permissions.u) {
        this.$router.push({ name: "error-404" });
      }
      this.information.id = this.$route.params.id;
      this.form.id = this.$route.params.id;
      await this.get();
    },

    async get() {
      try {
        this.loading();
        this.information = (
          await this.$http.get(`production/product/show/${this.information.id}`)
        ).data.data;
        this.list.nurseries =
          this.type === "addition"
            ? (
                await this.$http.get(`administration/nursery/index`, {
                  params: {
                    no_paginate: true,
                  },
                })
              ).data.data
            : (
                await this.$http.get(
                  `production/product/index-nursery/${this.information.id}`
                )
              ).data.data;
        if (this.type === "seedling") await this.getProducts();
        this.setHeaderSubTitle(
          `Id. ${this.information.id} / ${this.information.specie_name} / ${this.information.packaging_name}`
        );
      } catch (err) {
        if (err.response.status === 404) {
          this.$router.push({ name: "gestion-productos" });
        }
        this.notify("Error", err.response.data.message, "danger");
      } finally {
        this.not_loading();
      }
    },

    async productPopup() {
      this.form_product = {
        specie_id: this.information.specie_id,
        packaging_id: "",
        height: this.information.height,
        production_days: this.information.production_days,
        price: this.information.price,
        description: this.information.description,
      };
      this.create_product = true;
    },

    async getProducts() {
      try {
        this.list.products = (
          await this.$http.get(
            `production/product/index-other-products/${this.information.id}/${this.information.specie_id}`
          )
        ).data.data;
        this.list.species_not_created = (
          await this.$http.get(
            `production/product/index-specie-not-created/${this.information.specie_id}`
          )
        ).data.data;
      } catch (err) {
        throw err;
      }
    },

    async storeProduct() {
      this.$refs.form_product.validate().then((success) => {
        if (success) {
          this.loading();
          this.$http
            .post("/production/product/store", this.form_product)
            .then(async (res) => {
              this.notify(
                "Proceso satisfactorio.",
                res.data.message,
                "primary"
              );
              await this.getProducts();
              this.form.new_product_id = res.data.id
              this.create_product = false;
            })
            .catch((err) => {
              this.notify("Error", err.response.data.message, "danger");
            })
            .finally(() => {
              this.not_loading();
            });
        }
      });
    },

    adjustment() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const form = this.form.inventory;
          if (form.length < 1) {
            this.notify(
              "Error",
              "Debe rellenar la tabla para poder cargar informacion",
              "danger"
            );
            return;
          }
          this.loading();
          this.$http
            .post(`/production/${this.type}/store`, this.form)
            .then((res) => {
              this.notify(
                "Proceso satisfactorio.",
                res.data.message,
                "primary"
              );
              this.$router.push(`/gestion-productos/show/${this.form.product_id}`);
            })
            .catch((err) => {
              this.notify("Error", err.response.data.message, "danger");
            })
            .finally(() => {
              this.not_loading();
            });
        }
      });
    },

    async add() {
      if (this.filter.nursery_id === "") {
        this.notify(
          "Error",
          "Debe seleccionar el campos vivero para poder adicionar registro a la tabla",
          "danger"
        );
        return;
      }

      const index = this.form.inventory.findIndex(
        (p) => p.nursery_id == this.filter.nursery_id
      );
      if (index >= 0) {
        this.notify(
          "Error",
          "El vivero que esta tratando de adicionar ya se encuentra en la tabla",
          "danger"
        );
        return;
      }

      const nursey_values =
        this.list.nurseries[
          this.list.nurseries.findIndex((p) => p.id == this.filter.nursery_id)
        ];

      this.form.inventory.push( {
          nursery_id: this.filter.nursery_id,
          nursery: nursey_values.name,
          available: this.type === "addition" ? 0 : nursey_values.available,
          quantity: 0,
        });
    },

    remove(key) {
      this.form.inventory.splice(key, 1);
    },
  },
  computed: {
    fields() {
      if (this.type === "addition") return this.addition;
      else return this.generic;
    },

    generic() {
      return [
        this.action,
        this.nursery,
        this.available,
        this.quantity,
      ];
    },

    addition() {
      return [this.action, this.nursery, this.quantity];
    },
  },
};
</script>

<style scoped>
.custom-datepicker .dropdown-toggle::after {
  content: none;
  /* Oculta la flecha del cuadro de diálogo */
}
</style>