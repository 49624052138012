var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.permissions.u)?_c('button-top',{attrs:{"must-icon":false,"tooltip":"Guardar ajuste","name":"Guardar"},on:{"action":_vm.adjustment}}):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-form',[(_vm.type === 'addition')?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"planting_date"}},[_vm._v("Fecha de siembra")]),_c('validation-provider',{attrs:{"name":"Fecha de siembra","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"custom-datepicker",staticStyle:{"z-index":"100"},attrs:{"id":"planting_date","state":errors.length > 0 ? false : null,"name":"planting_date","hide-header":"","dropright":"","date-format-options":{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }},model:{value:(_vm.form.planting_date),callback:function ($$v) {_vm.$set(_vm.form, "planting_date", $$v)},expression:"form.planting_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,899794239)})],1)],1)],1):_vm._e(),(_vm.type === 'seedling')?_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"font-weight-bold",attrs:{"cols":"2"}},[_vm._v(" Especie ")]),_c('b-col',[_vm._v(" "+_vm._s(((_vm.information.specie_name) + " / " + (_vm.information.packaging_name)))+" ")])],1):_vm._e(),(_vm.type === 'seedling')?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"product_id"}},[_vm._v("Nuevo producto")]),_c('validation-provider',{attrs:{"name":"Nuevo producto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-select-created',{attrs:{"name":"product_id","id":"product_id","options":_vm.list.products,"on-value":"id","on-text":"name","created":_vm.list.species_not_created.length > 0,"state":errors.length > 0 ? false : null},on:{"create":_vm.productPopup},model:{value:(_vm.form.new_product_id),callback:function ($$v) {_vm.$set(_vm.form, "new_product_id", $$v)},expression:"form.new_product_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3762903279)})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"nursery_id"}},[_vm._v("Vivero")]),_c('b-form-select',{staticClass:"fl",attrs:{"id":"nursery_id","value-field":"id","text-field":"name","options":_vm.list.nurseries,"name":"nursery_id"},model:{value:(_vm.filter.nursery_id),callback:function ($$v) {_vm.$set(_vm.filter, "nursery_id", $$v)},expression:"filter.nursery_id"}})],1)],1)],1),_c('div',{staticClass:"mt-2"},[_c('b-table',{attrs:{"items":_vm.form.inventory,"fields":_vm.fields,"bordered":"","small":""},scopedSlots:_vm._u([{key:"thead-top",fn:function(){return [_c('b-tr',[_c('b-th',{staticClass:"bg-dark text-white",attrs:{"colspan":_vm.fields.length}},[_vm._v("Inventario en producción ")])],1)]},proxy:true},{key:"cell(action)",fn:function(row){return [_c('b-row',{attrs:{"align-h":"center"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Eliminar'),expression:"'Eliminar'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer",attrs:{"icon":"MinusCircleIcon","size":"16","color":"red"},on:{"click":function($event){return _vm.remove(row.index)}}})],1)]}},{key:"cell(quantity)",fn:function(row){return [_c('validation-provider',{attrs:{"name":"Cantidad","rules":'required|min_value:1|' +
                (_vm.type === 'addition' ? '' : ("max_value:" + (row.item.available)))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-100",attrs:{"id":"quantity","state":errors.length > 0 ? false : null,"name":"quantity","type":"number"},model:{value:(row.item.quantity),callback:function ($$v) {_vm.$set(row.item, "quantity", $$v)},expression:"row.item.quantity"}})]}}],null,true)})]}},{key:"cell(area_destination)",fn:function(row){return [_c('validation-provider',{attrs:{"name":"Era destino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{staticClass:"fl",attrs:{"id":"area_destination_id","value-field":"id","text-field":"name","options":row.item.options,"state":errors.length > 0 ? false : null,"name":"area_destination_id"},model:{value:(row.item.area_destination_id),callback:function ($$v) {_vm.$set(row.item, "area_destination_id", $$v)},expression:"row.item.area_destination_id"}})]}}],null,true)})]}}])}),_c('b-row',{attrs:{"align-h":"end"}},[(_vm.permissions.c)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.add()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}}),_c('span',{staticClass:"mr-25 align-middle"},[_vm._v("Adicionar registro")])],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Observacion")]),_c('validation-provider',{attrs:{"name":"Observacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"observation","state":errors.length > 0 ? false : null,"name":"observation","rows":"3","max-rows":"6"},model:{value:(_vm.form.observation),callback:function ($$v) {_vm.$set(_vm.form, "observation", $$v)},expression:"form.observation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-modal',{attrs:{"title":("Agregar para la especie: " + (_vm.information.packaging_name)),"size":"md","hide-footer":"","no-close-on-backdrop":""},model:{value:(_vm.create_product),callback:function ($$v) {_vm.create_product=$$v},expression:"create_product"}},[_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"form_product"},[_c('b-form-group',{staticClass:"w-100"},[_c('label',{staticClass:"text-success",attrs:{"for":"name"}},[_vm._v("Empaque")]),_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{staticClass:"fl",attrs:{"id":"packaging_id","value-field":"id","text-field":"packaging_name","options":_vm.list.species_not_created,"name":"packaging_id","state":errors.length > 0 ? false : null},model:{value:(_vm.form_product.packaging_id),callback:function ($$v) {_vm.$set(_vm.form_product, "packaging_id", $$v)},expression:"form_product.packaging_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{attrs:{"variant":"outline-secondary","block":""},on:{"click":function($event){_vm.create_product = false}}},[_vm._v("Cancelar")])],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.storeProduct()}}},[_vm._v("Crear")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }